import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import FetchData from './components/FetchData';
import { Counter } from './components/Counter';
import TopSearch from './components/TopSearch'

import './custom.css'

function App() {
    //const [toshow, setToshow] = useState(false);
    //const [mail, setMail] = useState('');
    //const checkMail = () => {
    //    if (mail == 'RANS@shahal.co.il') {
    //        setToshow(true)
    //    }
    //    else {
    //        alert('waiting!')
    //    }

    //}

return (
    <div>
        <TopSearch/>

    {/*   //<input type="text" value={mail} name='subTitle' onChange={a => setMail(a.target.value)} />*/}
    {/*//    <button onClick={() => checkMail()}>*/}
    {/*//        check*/}
    {/*//    </button>*/}
    {/*//    {toshow &&*/}
    {/*//        <FetchData />*/}
    </div>
);  
}

export default App;
