import React, { useState, useEffect } from "react";

function Prayer({ prayer }) {

    const [toShow, setToShow] = useState(false)
    return (
        <div className="prayerWidth">
            <h4 onClick={() => setToShow(!toShow)}>{prayer.title}</h4>
           
            {toShow && <h6 style={{marginTop:25}}>{prayer.content}</h6>}
        </div>);
}


export default Prayer;

