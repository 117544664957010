import React, { useState, useEffect } from "react";
import Prayer from "./Prayer"
function TopSearch() {

    const [tags, setTags] = useState([]);
    const [searchTxt, setSearchTxt] = useState("")
    const [prayers, setPrayers] = useState(undefined)
    useEffect(() => {

        const getTags = async () => {
            const response = await fetch(`alltags`);
            const data = await response.json();
            setTags(data)
        }
        getTags()
    }, []);
    const getParyersOfTag = async tagId => {
        const response = await fetch(`/PrayersOfTag/${tagId}`);
        const data = await response.json();
        setPrayers(data.filter(p => p.prayer?.confirmation))
    }
    const searchResults = tags?.filter(t => t.title?.includes(searchTxt) ).map(t => {
        return <div key={t.id} onClick={ ()=>getParyersOfTag(t.id)}>{t.title}</div>
    })

    const prayersList = prayers?.map(p => {
        return <div key={p.id}>
            <Prayer prayer={p?.prayer}/>
        </div>
    })
    return (
        <div>
            {!prayers ?
                <div className="topBG">
                    <div>
                        <h3>חיפוש נושא:</h3>
                    </div>
                    <div className="searchBorder">
                        <input value={searchTxt} onChange={e => setSearchTxt(e.target.value)} className="searchInput" /> </div>
                </div> : <div className="topBG2" onClick={() => setPrayers(undefined)}>
                    <div>
                        חיפוש חדש</div></div>}
            {!prayers ? <div style={{marginTop:"50vh"}}>
                <h1>
                    נושאים לתפילה:
                </h1>
                <div className="searchResults">{searchResults}</div>
                </div>
                    : <div> { prayersList }</div>
                }
        </div>);
}


export default TopSearch;

