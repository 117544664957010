import React, { useState, useEffect } from "react";
import PrayerPage from './PrayerPage'

function FetchData() {
    const [subjectList, setSubjectList] = useState([]);
    const [selected, setSelected] = useState({});
    const [prayers, setPrayers] = useState([]);
    const [selectedPrayer, setSelectedPrayers] = useState(undefined);
    const [showAll, setShowAll] = useState(false);
  
    useEffect(() => {
        const getList = async () => {
            const response = await fetch('weatherforecast');
            const data = await response.json();
            setSubjectList(data)
            setSelected(data[0])

            const response2 = await fetch(`prayersofsubject`);
            const data2 = await response2.json();
            setPrayers(data2)

        }
        getList()
    }, []);

    const removeSubject = async (id) => {
        const response = await fetch(`/removeSubject/${id}`);
        const data = await response.json();
        setSubjectList(data)
    }


    const updateList = async () => {    
    setSelectedPrayers(undefined)
        const response2 = await fetch(`prayersofsubject`);
        const data2 = await response2.json();
        window.scrollTo(0, 0);
        setPrayers(data2)
        }


    const getPrayer = async (id) => {
        const response = await fetch(`/prayer/${id}`);
        const data = await response.json();        
        setSelectedPrayers(data)
    }

    const subjectLength = (id) => subjectList?.filter(s => s.parentId == id).length
    const prayersLength = (id) => prayers?.filter(p => p.subjectId == id && !p.confirmation).length
    const ToShowSubject = (id) => {
        if (showAll) {
            return true
        } else {
            return prayersLength(id) > 0 || subjectLength(id) > 0
        }

    }

    const subfolders = subjectList?.filter(s => s.parentId == selected.id && ToShowSubject(s.id)).map(s =>
        <h4 key={s.id} style={{ display:"flex" }} >
            < p onClick={() => {
                setSelected(s); setSelectedPrayers(undefined);
            }}> {s.title}
            </p>
            {/*<p onClick={() => removeSubject(s.id)}>*/}
            {/*       [DLT]*/}
            {/*    </p>*/}
           
            
        </h4>

    )

    const goBack = () => {
        console.log(selected?.id)
        if (selected?.id >= 1 && selected?.parentId && !ToShowSubject(selected?.id)) {
            console.log(selected?.parentId)
            setSelected(subjectList?.find(s => s.id == selected.parentId))
        }
    }
    goBack()

    


    const getParents = () => {
        let parentsArr = []
        let s = selected
        parentsArr.push(s)
        if (Object.keys(s).length) {
            while (s?.parentId !== 0) {
                s = subjectList?.find(ss => ss.id == s.parentId)
                parentsArr.push(s)
            }
            return parentsArr
        }
    }
   
    const history = (getParents()?.map(s =>
        <h2 key={s.id} className="history"> {">"}
            <p onClick={() => { setSelected(s); setSelectedPrayers(undefined) }}>
                {s.title}
            </p>
            </h2>
    ))
    const prayersList = (prayers?.filter(p => p.subjectId == selected.id).map(p =>
        <div key={p.id} className="prayer" style={{ background: p.confirmation ? "blue" : "navy", display: !p.confirmation || showAll ? "block" : "none" }} onClick={() => getPrayer(p.id)}>
                {p.title}
        </div>
    ))

    return (<div>
        <button onClick={() => setShowAll(!showAll)}>
            {showAll ? "Hide" :"SHOW"}
        </button>
        <div className="history">
            {history}
        </div>
        <div style={{ display: !selectedPrayer ? "block" : "none" }}>
            {subfolders}
            {prayersList}
        </div>
        <div style={{ display: selectedPrayer ? "block" : "none" }}>
            <PrayerPage details={selectedPrayer} afterEdit={() => updateList()} />
        </div>
    </div>);
}


export default FetchData;

